import React, { useState } from 'react';
import DescriptionModal from './DescriptionModal';

const Experience = ({ title, company, duration, fullDescription }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <div className="experience-modal">
    <h2 className="text-xl font-bold mb-2" id="expTitle">{title}</h2>
      <p>{company}</p>
      <p>{duration}</p>
      <button className='full-description-btn' onClick={toggleModal}>Full Description</button>
      {modalOpen && <DescriptionModal description={fullDescription} toggleModal={toggleModal} />}
    </div>
  );
};

export default Experience;
