import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import CELLS from 'vanta/dist/vanta.cells.min';

const VantaBackground = () => {
  const vantaRef = useRef(null);
  useEffect(() => {
    if (!vantaRef.current) return;
    const vantaEffect = CELLS({
      el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color1: 0x3a27e8,
      color2: 0x775a4
    });
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaRef]);

  return <div ref={vantaRef} className="vanta-background" />;
};

export default VantaBackground;
