import React from 'react';
import Header from './components/Header';
import Experience from './components/Experience';
import VantaBackground from './components/VantaBackground';
import Project from './components/Project';
import './App.css';

function App() {
  return (
    <div className="App">
      <VantaBackground />
      <Header />
      <main>
        <section id="experience" className="p-4">
        <Experience 
  title=".NET Developer"
  company="Illinois Mutual"
  duration="April 2022 - March 2023"
  fullDescription={`As a dedicated Software Developer at Illinois Mutual, I led the design and implementation of an advanced web service using ASP.NET MVC, revolutionizing the way roles were assigned to mail codes for agents and markedly boosting system productivity and performance.

My contributions included crafting a modernized login interface for the MyPolicy portal utilizing .NET framework 4.8, HTML, and CSS, which significantly enhanced the policy owner experience with its user-friendly design and visual appeal.

I played a pivotal role in the migration of the Illinois Mutual Agent Forum portal to .NET Core 7, improving scalability and maintainability. My technical skills were further demonstrated through the development of intricate SQL queries and stored procedures for the company’s Microsoft SQL server, ensuring the database infrastructure was fully optimized for high performance and capable of managing vast datasets efficiently.

Committed to software excellence, I meticulously debugged and unit tested various web APIs using Swagger, ensuring their reliability and error-free operation. My expertise is built upon extensive training in key industry technologies and methodologies, including ASP.NET, C# MVC Pattern, Angular, Neuron ESB, and SQL, as well as .NET CORE. I have a comprehensive understanding of the software development lifecycle and Agile methodology, which empowers me to consistently deliver high-quality projects that meet both time and budget requirements.`}
/>
          <Experience 
            title="Full Stack Development Intern"
            company="Kalo Technologies"
            duration="November 2021 - March 2022"
            fullDescription="At Kalo Technologies, my role as a Software Developer encompassed an extensive training regime that equipped me with a solid foundation in front-end development, including HTML, CSS, and JavaScript, along with the essentials of QA testing and cloud computing. Leveraging these skills, I implemented a variety of features using HTML, CSS, JavaScript, and Bootstrap for web projects aimed at helping clients expand their businesses and elevate their brands. This multifaceted expertise allowed me to not only contribute to the technical aesthetic and functionality of client projects but also to ensure their robustness and scalability through systematic testing and the utilization of cloud resources."
          />
          <Experience 
            title="Game Programming Intern"
            company="Silver Gear Interactive"
            duration="December 2020 - January 2021"
            fullDescription="At Silver Gear Interactive, a indie game studio, I embraced the challenge of bringing virtual worlds to life as a Software Developer specializing in game mechanics and AI design. My role involved authoring robust C# scripts within Unity to create engaging game mechanics and intelligent enemy behaviors, which were fundamental to the game's interactive experience.

            I applied my creative and technical skills to design intricate levels using Unity's tile map feature, thoughtfully considering structure and enemy placements to enhance gameplay and challenge players. My commitment to quality was demonstrated through rigorous testing and debugging of game features, ensuring a seamless and enjoyable user experience.
            
            I was actively involved in the creative process, proposing innovative ideas for new game features, including an array of weapons and unique concepts for enemy and level design. These contributions were aimed at enriching the game’s complexity and providing a richer, more dynamic gaming experience for players."
          />
          {/* Add more Experience components as needed */}
        </section>
        <section id="projects" className="project_container">
  <Project 
    title="Master's Student Tracking "
    description="MAST is a sophisticated web application designed for academic management, particularly focusing on student and course administration for grad students. It provides a comprehensive platform for managing student information, course enrollments, and degree requirements. Features include enrollment trend analysis, course plan suggestions, and file management. Its intuitive interface and robust functionality make it an ideal solution for efficient and effective academic management.





    "
    link="https://github.com/Liam-Westhall/MAST"
  />
  <Project 
    title="BidMaster "
    description="A Java-based auction management application, BidMaster offers a comprehensive suite of tools for efficient auction operations. It features robust modules for customer and employee management, bid tracking, and auction handling, streamlining the entire auction process from start to finish. Designed with scalability in mind, BidMaster is an ideal solution for both small-scale and large-scale auction environments."
    link="https://github.com/Liam-Westhall/Java-Auction-Website"
  />
  <Project 
    title="Vocas"
    description="Vocas is a dynamic web application tailored for language enthusiasts and learners. It serves as an interactive tool for expanding and managing personal vocabularies. With its intuitive interface, users can effortlessly add, categorize, and review words in multiple languages. Each entry in Vocas includes detailed attributes like definitions, examples, and parts of speech, enhancing the learning experience. Designed with a focus on user engagement and educational value, Vocas is an essential companion for anyone looking to enrich their linguistic skills.

    "
    link="https://github.com/Liam-Westhall/Vocas"
  />
  <Project 
    title="GoLogoLo LogoMaker"
    description="GoLogoLo is an innovative web application designed for creating and customizing logos. This application empowers users with a user-friendly interface to design logos that cater to their specific branding needs. 

Users can easily create logos with a variety of customization options, including title, color, font size, background color, border properties, and dimensions.
   The app also offers a dynamic editing experience where changes are reflected in real-time, allowing users to adjust and fine-tune their designs seamlessly.
    "
    link="https://github.com/Liam-Westhall/GoLogoLo"
  />
        </section>
      </main>
    </div>
  );
}

export default App;
