// DescriptionModal.js

import React from 'react';

const DescriptionModal = ({ description, toggleModal }) => {
  // Split the description into paragraphs based on newlines
  const paragraphs = description.split('\n').filter(paragraph => paragraph.trim().length > 0);

  return (
    <div className="description-modal-overlay">
      <div className="description-modal-content">
        {paragraphs.map((paragraph, index) => (
          // Render each paragraph in a separate <p> tag
          <p key={index} className="modal-description-text">{paragraph}</p>
        ))}
        <button className="modal-close-btn" onClick={toggleModal}>Close</button>
      </div>
    </div>
  );
};

export default DescriptionModal;
