import React from 'react';
const profilePic = "/LiamProfile2.0.jpg";

const Header = () => {
  return (
    <header className="h-screen flex justify-center items-center bg-gray-800 text-white">
      <div className="flex flex-col items-center space-y-4">
        <img src={profilePic} alt="Profile Picture" className="profile-picture"/>
        <h1 className="header-title text-4xl">Liam Westhall</h1>
        <div className="header-nav flex space-x-4">
          <a href="https://github.com/Liam-Westhall" target="_blank" rel="noopener noreferrer" className="hover:underline">
            GitHub
          </a>
          <a href="https://www.linkedin.com/in/liam-westhall-13ba41188/" target="_blank" rel="noopener noreferrer" className="hover:underline">
            LinkedIn
          </a>
          <a href="mailto:liwesthall@gmail.com" className="hover:underline">
            Email
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;

